// src/store/language.js

import { defineStore } from 'pinia';
import { LOCAL } from '@/constants';
import helpers from '@/helpers';
export const useLanguageStore = defineStore('language', {
  state: () => ({
    selectedLocale: helpers.getDomainConfig().firstLang,
    isChanged: false
  }),
  getters: {
    isRtlLayout: (state) => ['ar', 'fa', 'he', 'ps', 'ur'].includes(state.selectedLocale),
    getIsChanged: (state) => state.isChanged
  },
  actions: {
    setLocale(locale) {

      if (this.selectedLocale !== locale) {
        // Set the selected locale in the store
        this.selectedLocale = locale;
        // Store the selected locale in local storage
        localStorage.setItem(LOCAL, locale);

        window.location.reload()
        
      }
    },
    initLocale() {
      // Retrieve the selected locale from local storage when the app starts
      const storedLocale = localStorage.getItem(LOCAL);
      if (storedLocale) {
        this.selectedLocale = storedLocale;
      } else {
        this.selectedLocale = helpers.getDomainConfig().firstLang;

        localStorage.setItem(LOCAL, this.selectedLocale);
      }
    },
  },
});
