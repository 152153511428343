export const items = [
  {
    id: 1,
    title: 'IO',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/other.svg'
  },
  {
    id: 2,
    title: '2 Player',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/2-player.svg'
  },
  {
    id: 3,
    title: '3D',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/3d.svg'
  },
  {
    id: 4,
    title: 'Actions',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/actions.svg'
  },
  {
    id: 5,
    title: 'Adventure',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/adventure.svg'
  },
  {
    id: 6,
    title: 'Arcade',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/arcade.svg'
  },
  {
    id: 7,
    title: 'Bejeweled',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/bejeweled.svg'
  },
  {
    id: 8,
    title: 'Clicker',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/clicker.svg'
  },
  {
    id: 9,
    title: 'Farming',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/farming.svg'
  },
  {
    id: 10,
    title: 'Casual',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/casual.svg'
  },
  {
    id: 11,
    title: 'Puzzle',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/puzzle.svg'
  },
  {
    id: 12,
    title: 'Racing',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/racing.svg'
  },
  {
    id: 13,
    title: 'Puzzle',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/puzzle.svg'
  },
  {
    id: 14,
    title: 'Play',
    url: 'https://theme.cloudarcade.net/content/themes/arcade-one/images/icon/puzzle.svg'
  },
];
