<template>
  <nav
    :class="[
      'items-center justify-between hidden mx-auto text-white md:flex fixed inset-x-0 z-50 transition-all duration-300 ease-in-out px-8',
      isScrolled ? 'bg-[#151436] py-1' : 'bg-transparent gradient-shadow py-8',
    ]"
    :dir="isRtlLayout ? 'rtl' : 'ltr'"
  >
    <div
      class="cursor-pointer"
      :class="isHovered ? '' : '-ml-'"
      @click="$router.push({ name: 'home' })"
    >
      <img src="@/assets/logo-h-w.png" alt="logo" :class="[
        'object-cover transition-all duration-300 ease-in-out',
        isScrolled ? 'h-[64px] py-3' : 'h-[84px]',
      ]" />
    </div>
    <div class="flex items-center space-x-2 relative">
      <div id="search" class="hidden md:block">
        <search-view />
      </div>
      <router-link
        to="/"
        class="font-bold p-4 leading-none rounded-full hover:bg-[#262653]"
        >{{ $t("home") }}</router-link
      >

      <router-link
      to="/leaderboard"
        class="font-bold p-4 leading-none rounded-full hover:bg-[#262653]"
      >
        {{ $t("Leaderboard") }}
      </router-link>

      <!-- Dropdown button for logged-in users -->
      <div v-if="isLoggedIn" class="relative">
        <button
          @click="toggleDropdown"
          class="font-bold p-4 leading-none rounded-full hover:bg-[#262653]"
        >
          {{ $t("Profile") }}
        </button>

        <!-- Dropdown menu -->
        <div
          v-if="isDropdownOpen"
          class="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-md"
          style="z-index: 10"
        >
          <ul class="py-1">
            <li>
              <router-link
                to="/user/profile"
                @click="toggleDropdown"
                class="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                >{{ $t("Profile") }}</router-link
              >
            </li>
            <li v-if="userInfo && userInfo.is_subscribed">
              <router-link
                to="/unsubscribe"
                @click="toggleDropdown"
                class="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                >{{ $t("Unsubscribe") }}</router-link
              >
            </li>
            <li v-else>
              <a
                @click="openSubscription"
                href="javascript:void(0)"
                class="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                >{{ $t("Subscribe") }}</a
              >
            </li>
            <li>
              <a
                @click="onLogout"
                href="javascript:void(0)"
                class="block px-4 py-2 text-gray-800 hover:bg-gray-200"
                >{{ $t("Logout") }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <!-- Login link for non-logged-in users -->
      <a
        v-else
        href="javascript:void(0)"
        @click="openLogin"
        class="font-bold p-4 leading-none rounded-full hover:bg-[#262653]"
        rel="noopener noreferrer"
        >{{ $t("Subscribe/Login") }}</a
      >

      <button
        @click="setLanguage"
        class="inline-flex items-center justify-center gap-2 font-bold p-4 leading-none rounded-full hover:bg-[#262653]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"
          />
        </svg>

        {{ $t("language") }}
      </button>
    </div>
  </nav>
</template>

<script>
import { useSearchStore } from "@/store/search";
import { mapActions, mapState } from "pinia";
import SearchView from "../Shared/SearchView.vue";
import { useLanguageStore } from "@/store/language";
import { useAuthStore } from "@/store/auth";
import { LOCAL } from "@/constants";
import helpers from "@/helpers";

export default {
  inject: ["isRtlLayout"],
  components: { SearchView },

  data() {
    return {
      items: ["Home", "Subscribe", "Login"],
      isScrolled: false,
      isDropdownOpen: false,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["isLoggedIn", "userInfo"]),
  },
  methods: {
    ...mapActions(useSearchStore, ["fetchSearchData"]),
    ...mapActions(useLanguageStore, ["setLocale"]),
    ...mapActions(useAuthStore, ["logout", "unsubscribe"]),
    setLanguage() {
      const firstLang = helpers.getDomainConfig().firstLang;
      const secondLang = helpers.getDomainConfig().secondLang;
      let locale =
        localStorage.getItem(LOCAL) == secondLang ? firstLang : secondLang;
      this.$i18n.locale = locale;
      this.setLocale(locale);
    },

    async fetchSearch() {
      if (this.query == "") return;

      await this.fetchSearchData(this.query).then((response) => {
        if (response.data) {
          this.query = "";
          this.$router.push("/search/results");
        }
      });
    },
    openSubscription() {
      localStorage.setItem("redirectUrl", this.$route.fullPath);
      window.location.href = helpers.getDomainConfig().subscribeUrl;
    },
    openLogin() {
      localStorage.setItem("redirectUrl", this.$route.fullPath);
      this.isDropdownOpen = false;
      this.$router.push({ name: "user.login" });
    },
    async onLogout() {
      this.isDropdownOpen = false;
      await this.logout();
      // Go to home or redirect the home if the user is on the home page
      this.$router.push("/");
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
  },
  mounted() {
    this.isDropdownOpen = false;
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  created() {
    this.$i18n.locale = localStorage.getItem(LOCAL);
  },
};
</script>

<style scoped>
.gradient-shadow {
  background-image: linear-gradient(180deg, 
    rgb(0 0 0 / 45%) 30%, 
    rgba(0, 0, 0, 30%) 75%, 
    rgb(0 0 0 / 0%) 100%
  );
  /* background-image: linear-gradient(215deg, #2d2b2b 0%, #442f3d72 50%, #46474145 75%); */
  color: white;
}

.shadow-lg {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
</style>
