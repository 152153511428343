import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // If the saved position is available (like on browser back/forward), use it
    if (savedPosition) {
      return savedPosition;
    } else {
      // Always scroll to the top for new page loads
      return { top: 0 };
    }
  },
})

export default router
