import { LOCAL, domainsConfig } from "@/constants";
import toastr from 'toastr';
import 'toastr/build/toastr.css';

function getDomainConfig() {
    const hostname = window.location.hostname;

    return domainsConfig[hostname] || {};
}

function showToast(message, title, type){
    const local = localStorage.getItem(LOCAL) || getDomainConfig().firstLang;
    let isRtlLayout = ['ar', 'fa', 'he', 'ps', 'ur'].includes(local);

    toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: false,
        progressBar: true,
        positionClass: isRtlLayout ? 'toast-bottom-left': 'toast-bottom-right',
        preventDuplicates: false,
        onclick: null,
        showDuration: '300',
        hideDuration: '1000',
        timeOut: '5000',
        extendedTimeOut: '1000',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut'
    };

    if(type == 'success'){
        toastr.success(message, title);
    } else if(type == 'error'){
        toastr.error(message, title);
    } else if(type == 'warning'){
        toastr.warning(message, title);
    } else if(type == 'info'){
        toastr.info(message, title);
    }
}
export default {
    getDomainConfig,
    showToast,
};