<!-- HamburgerMenu.vue -->
<template>
    <div @click="toggleLeftSidebar" class="cursor-pointer items-center flex">
      <!-- Your hamburger menu icon or text goes here -->
      <!-- For example, using Font Awesome for an icon -->
      <humberger-view/>
     
    </div>
  </template>
  
  <script>
  import HumbergerView from '../svg/HumbergerView.vue';

  export default {
  components: { HumbergerView },
    methods: {
      toggleLeftSidebar() {
        // Emit an event to the parent component to handle sidebar toggling
        this.$emit('toggle-left-sidebar');
      }
    }
  };
  </script>
  