<template>
  <img src="@/assets/logo-h-w.png" alt="logo" class="object-cover">
</template>

<script>
export default {
  components: []
}
</script>

<style></style>
