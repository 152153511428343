// Import necessary modules from Vue and other dependencies
import { createApp, computed } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import i18n from "./i18n";
import router from "./router";
import "./index.css";
import { useLanguageStore } from "./store/language";
import helpers from "./helpers";
import { useAuthStore } from './store/auth';
import axios from "axios";
import { eventBus } from './eventBus';

/**
 * Initialize the Vue application
 * This function creates a new Vue application with the root component (App)
 * and applies the necessary plugins (Pinia for state management, router for routing, and i18n for internationalization)
 * @returns {Vue.App} The initialized Vue application
 */
function initializeApp() {
  const app = createApp(App);
  const pinia = createPinia();

  let requestCount = 0;
  function showLoading() {
    requestCount++;
    eventBus.emit('loading', true);
  }
  
  function hideLoading() {
    requestCount--;
    if (requestCount === 0) {
      eventBus.emit('loading', false);
    }
  }
  axios.interceptors.request.use(config => {
    showLoading();
    return config;
  }, error => {
    hideLoading();
    return Promise.reject(error);
  });
  
  axios.interceptors.response.use(response => {
    hideLoading();
    return response;
  }, error => {
    hideLoading();
    return Promise.reject(error);
  });
  

  router.beforeEach((to, from, next) => {

    const authStore = useAuthStore()
    const isAuthenticated = authStore.getIsloggedIn
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!isAuthenticated) {
        // User is not authenticated, redirect to login page
        next('/')
      }
    }

    let domainConfig = helpers.getDomainConfig();
    let title = domainConfig.title;

    // Default title if domain is not matched
    let defaultTitle = "Mars";

    if (to.name == "home") {
      defaultTitle = "Home";
    } else if (to.name == "user.register") {
      defaultTitle = "Register";
    } else if (to.name == "user.login") {
      defaultTitle = "Login";
    } else if (to.name == "user.profile") {
      defaultTitle = "Profile";
    } else if (to.name == "policy") {
      defaultTitle = "Policy";
    } else if (to.name == "terms") {
      defaultTitle = "Terms and Conditions";
    } else if (to.name == "game.show") {
      defaultTitle = "Game";
    } else if (to.name == "category.show") {
      defaultTitle = "Category";
    }

    // Set document title from domain
    document.title = (defaultTitle + (title ? " | " + title : ""));
    next();
  });
  
  app.use(pinia);
  app.use(router);
  app.use(i18n);

  return app;
}

/**
 * Initialize the locale for the application
 * This function retrieves the language store and initializes the locale
 * This is necessary for the i18n plugin to know which language to use when the application starts
 */
function initializeLocale() {
  const languageStore = useLanguageStore();
  languageStore.initLocale();
}

// Initialize the Vue application
const app = initializeApp();

// Initialize the locale
initializeLocale();

// Provide the isRtlLayout computed property to the entire application
// This allows any component in the application to inject and use isRtlLayout
// isRtlLayout is a computed property that determines whether the layout should be right-to-left based on the current language
const languageStore = useLanguageStore();
app.provide(
  "isRtlLayout",
  computed(() => languageStore.isRtlLayout)
);

// Mount the Vue application to the DOM
app.mount("#app");
