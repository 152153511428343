export const AUTHTOKEN = "auth_token";
export const LOCAL = "local";

export const domainsConfig = {
  "localhost": {
    title: "Mars Local",
    productId: 1001,
    subscribeUrl: "http://localhost:8080/subscribe?msisdn=971509150991",
    apiKey: "123456",
    firstLang: "ar",
    secondLang: "en",
    secretKey: "0191e9c0-bee0-718e-80a1-f977e5abcdcf",
  },
  "mars-ae.enthalpytech.com": {
    title: "Mars UAE",
    productId: 971007005,
    subscribeUrl: "https://digital.enthalpytech.com/landing/mars-uae",
    apiKey: "123456",
    firstLang: "en",
    secondLang: "ar",
    secretKey: "0191ea11-474c-7d7c-b545-3c7b1882e9c6",
  },
  "mars.enthalpytech.com": {
    title: "Mars Demo",
    productId: 1001,
    subscribeUrl: "https://mars.enthalpytech.com/subscribe?msisdn=971501234567",
    apiKey: "123456",
    firstLang: "en",
    secondLang: "ar",
    secretKey: "0191ea11-702f-7819-a49c-1c5d922cb8ea",
  },
  "mars-bh.enthalpytech.com": {
    title: "Mars Bahrain",
    productId: 27940,
    subscribeUrl: "https://digital.enthalpytech.com/landing/mars-bh",
    apiKey: "123456",
    firstLang: "en",
    secondLang: "ar",
    secretKey: "0191ea11-8e90-7f82-9953-8650479f169c",
  },
  "mars-sd.enthalpytech.com": {
    title: "Mars Sudani",
    productId: 1001,
    subscribeUrl: "https://mars-sd.enthalpytech.com/subscribe?msisdn=249120120630",
    apiKey: "123456",
    firstLang: "ar",
    secondLang: "en",
    secretKey: "0191ea11-b2c0-79ae-be63-c2b7cff8cd99",
  },
  "mars-et.enthalpytech.com": {
    title: "Mars Ethiopia",
    productId: 1001,
    subscribeUrl: "https://mars-et.enthalpytech.com/subscribe?msisdn=251123456789",
    apiKey: "123456",
    firstLang: "en",
    secondLang: "am",
    secretKey: "0191ea11-c6de-74dc-8c0e-79f0d81ec680",
  },
  "mars-ng.enthalpytech.com": {
    title: "Mars Nigeria",
    productId: 1001,
    subscribeUrl: "https://mars-ng.enthalpytech.com/subscribe?msisdn=234123456789",
    apiKey: "123456",
    firstLang: "en",
    secondLang: "ar",
    secretKey: "0192ae6f-4247-77b7-bb44-cd6ae22f1296",
  },
};
