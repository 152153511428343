<template>
  <div class="md:mx-24">
    <footer class="z-40 w-full justify-center">
      <div class="container mx-auto">
<!--     
        <div
          class="grid gird-cols-1 md:grid-cols-3 gap-3 md:p-12 py-3"
        >
          <div class="">
            <h1 class="text-gray-200 md:text-xl text-lg">Footer1</h1>
            <p class="text-gray-200 text-sm">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae
              quae alias odio dolorem, provident praesentium doloribus maxime
              odit placeat, saepe rerum incidunt temporibus repudiandae sunt
              eveniet unde laboriosam voluptatem aliquam.
            </p>
          </div>
          <div class="">
            <h1 class="text-gray-200 md:text-xl text-lg">Footer1</h1>
            <p class="text-gray-200 text-sm">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae
              quae alias odio dolorem, provident praesentium doloribus maxime
              odit placeat, saepe rerum incidunt temporibus repudiandae sunt
              eveniet unde laboriosam voluptatem aliquam.
            </p>
          </div>
          <div class="">
            <h1 class="text-gray-200 md:text-xl text-lg">Footer1</h1>
            <p class="text-gray-200 text-sm">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae
              quae alias odio dolorem, provident praesentium doloribus maxime
              odit placeat, saepe rerum incidunt temporibus repudiandae sunt
              eveniet unde laboriosam voluptatem aliquam.
            </p>
        
        </div>
      </div> -->

      <div class="flex justify-center space-x-3 md:p-12 py-3">
        <ul class="flex justify-center space-x-4 text-gray-200 ">
          <li style="margin: 0 0.5rem;"><router-link to="/about" class="md:text-md text-[12px]">{{ $t("About Us") }}</router-link></li>
          <li><router-link to="/terms" class="md:text-md text-[12px]">{{ $t("Terms") }}</router-link></li>
          <li><router-link to="/policy" class="md:text-md text-[12px]"> {{ $t("Policy & Privacy") }}</router-link></li>
          <li><router-link to="/contacts" class="md:text-md text-[12px]">{{ $t("Contact Us") }}</router-link></li>
        
        </ul>
      </div>
      </div>
      <div class="flex flex-col text-center h-16  w-full">
        <p class="text-xs text-gray-400 mt-8">
          © 2024 {{ $t("Enthalpy Technologies All Rights Reserved") }}.
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
