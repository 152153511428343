<template>
  <div class="loading-overlay" v-if="isLoading">
    <div class="loading-message">
        <img src="@/assets/logo-v-w.png" alt="logo" class="object-cover md:h-[12rem] h-[10rem] ">
        <p 
          class="text-center mt-4 text-white font-bold"
        >{{ $t("Loading") }}...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingDialog',
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.loading-message {
  color: white;
  font-size: 1.5em;
}
</style>
