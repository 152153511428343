import axios from 'axios';
import CryptoJS from 'crypto-js';
import { AUTHTOKEN, LOCAL } from '@/constants';
import { eventBus } from '@/eventBus';
import helpers from '@/helpers';

const TEST_URL = 'https://gamesapi.enthalpytech.com/api';
const BASE_URL = "https://gamesapi.enthalpytech.com/api"

/***
 * this function to create axios instance 
 * set up the request interceptor for the axios instance
 * @param url |default baseurl
 * 
 * @returns  an axios instance for the desired url
 */
const createAxiosInstance = (baseURL) => {
  const hostname = window.location.hostname;
  baseURL = hostname == 'localhost' ? TEST_URL : baseURL;
  const instance = axios.create({
    baseURL,
    headers: {
      "Accept": 'application/json',
      "Content-Type": 'application/json'
    }
  });

  instance.interceptors.request.use((config) => {
    const token = localStorage.getItem(AUTHTOKEN);
    const locale = localStorage.getItem(LOCAL);
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers['Accept-Language'] = locale ? locale : helpers.getDomainConfig().firstLang;

    const timestamp = Math.floor(Date.now() / 1000);
    const secretKey = helpers.getDomainConfig().secretKey;

    const signature = CryptoJS.SHA256(secretKey + timestamp).toString(CryptoJS.enc.Hex);

    config.headers['X-timestamp'] = timestamp;
    config.headers['X-Signature'] = signature;
    
    const showLoading = config.showLoading !== false;
    if (showLoading) {
      eventBus.emit('loading', true);
    }

    // lang to request with lang name
    return config;
  }, error => {
    eventBus.emit('loading', false);
    return Promise.reject(error);
  });
  instance.interceptors.response.use((response) => {
    const showLoading = response.config.showLoading !== false;
    if (showLoading) {
      eventBus.emit('loading', false);
    }
    return response;
  }, error => {
    const showLoading = error.config.showLoading !== false;
    if (showLoading) {
      eventBus.emit('loading', false);
    }
    return Promise.reject(error);
  });

  return instance;
}

export const http = createAxiosInstance(BASE_URL);
// export const auth = createAxiosInstance(BASE_URL);


// export const test = createAxiosInstance(TEST_URL);
// export const testAuth = createAxiosInstance(TEST_URL);
