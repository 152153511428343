import { defineStore } from "pinia";
import { http } from "./https";
import { LOCAL } from "@/constants";
import helpers from "@/helpers";

export const useSearchStore = defineStore("search", {
  state: () => ({
    games: [],
  }),
  getters: {
    getSearchResults: (state) => state.games,
  },
  actions: {
    async fetchSearchData(query) {
      return new Promise((resolve) => {
        const selectedLocale = localStorage.getItem(LOCAL) || helpers.getDomainConfig().firstLang;
        let url = `/search?title=${query}&lang=${selectedLocale}`;
        http
          .get(url)
          .then((response) => {
            this.games = response.data.result;

            resolve(response);
          })
          .catch((error) => {
            console.error(error);
          });
      });
    },
  },
});
